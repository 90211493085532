import React, { useEffect } from 'react'
import Header from "../../components/header"
import HeaderMenu from "../../components/header-menu"
import Footer from "../../components/footer"
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import { getUser} from "../../services/auth"

const VisitesVeterinaris = props => {


  
  function goBack(event){
    event.preventDefault();
    navigate("../../mainMenu")        
  }

  useEffect( () => {
    if (!getUser() || getUser().nivell.veterinari!==1) {
       navigate("../../mainMenu") 
    }
  })

  return (
    <span>
      <div className="granjatec-wrapper">
        <Header/>
        <HeaderMenu />
        <div>
      </div>
        <div className="main-container">
          <section className="section choose-opt">
            
            <Link className="btn-primary" to="/veterinaris/granjaVeterinari" state={{ typeGranja: "Engreix" }}>Engreix</Link>
            
            <span/>
                   
            <Link className="btn-primary" to="/veterinaris/granjaVeterinari" state={{ typeGranja: "Destete" }}>Destete</Link>
            <span/>

            <Link className="btn-primary" to="/veterinaris/granjaVeterinari" state={{ typeGranja: "Mares" }}>Mares</Link>
            <span/>       
                      
            <Link className="btn-close" to="#" onClick={goBack}>&lt;&lt;&nbsp;Anterior</Link>   
          </section>
        </div>
        <Footer/>
      </div>
      </span>
  )

}

export default VisitesVeterinaris